import React from 'react';

import TextareaAutosize from 'react-textarea-autosize';
import { NotifContainer } from './Notifs';

import callAPI from '../utilities/callAPI';
import { username } from '..';

const maxTitleLength = 200;
const maxContentLength = 2000;

/**
 * Component for writing and creating new Posts.
 */
export default class PostForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // whether the contents are valid to submit
      valid: false,
      // title of the post
      title: "",
      // content/body of the post
      content: "",
      // whether the post is a comment
      isComment: props.comment || false,
      // id of the parent post, see ^
      postId: props.postId || ""
    };
  }

  handleChange = (event) => {
    // update post information
    const target = event.target;
    const name = target.name;
    const value = target.value;
    this.setState({
      [name]: value
    });

    // determine if the post is now valid to send
    let valid = false;
    if (value.length > 0) {
      if (name === "title") {
        let len = this.state.content.length;
        if (value.length <= maxTitleLength && between(len, 1, maxContentLength)) {
          valid = true;
        }
      } else if (name === "content") {
        let len = this.state.title.length;
        if (value.length < maxContentLength && (between(len, 1, maxTitleLength) || this.state.isComment)) {
          valid = true;
        } 
      }
    }
    this.setState({ valid: valid });
  }

  handleSubmit = async (event) => {
    // prevent default form action
    event.preventDefault();
    if (this.state.valid) {
      // build post object
      let obj = {};
      obj.username = username;
      obj.date = new Date().getTime();
      obj.title = this.state.title;
      obj.content = this.state.content;
      if (this.state.isComment) {
        obj.parent = this.state.postId;
      }

      // call API
      const response = await callAPI("POST", JSON.stringify(obj), (this.state.isComment ? "/comments" : "/posts"));
      if (response.success) {
        // clear form contents
        this.setState({ title: "", content: "", valid: false });

        // update post object with relevant data
        let data = response.data;
        if (!this.state.isComment) {
          obj.title = data.title;
        }
        obj.content = data.content;
        obj.likes = [];
        obj.id = data.id;

        // do something with post object
        this.props.onPost(obj);
      } else {
        NotifContainer.error("Failed to post", response.data);
      }
    }
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit} className={"post-form " + (this.state.isComment ? "comment" : "")}>
        {!this.state.isComment &&
          <TextareaAutosize
            type="text"
            name="title"
            className="title"
            value={this.state.title}
            onChange={this.handleChange}
            placeholder="A Nice Title"
            maxLength={maxTitleLength}
            minRows="1"
            maxRows="5"
          />
        }
        {!this.state.isComment &&
          <span className={"count" + (this.state.title.length >= maxTitleLength / 2 ? "" : " hidden")}>
            {this.state.title.length + "/" + maxTitleLength}
          </span>
        }

        <TextareaAutosize
          name="content"
          className="content"
          value={this.state.content}
          onChange={this.handleChange}
          placeholder={this.state.isComment ? "Your Comment" : "Your Post"}
          maxLength={maxContentLength}
          minRows={this.state.isComment ? "1" : "5"}
          maxRows={this.state.isComment ? "10" : "25"}
        />
        <span className={"count" + (this.state.content.length >= maxContentLength / 2 ? "" : " hidden")}>
          {this.state.content.length + "/" + maxContentLength}
        </span>

        <input type="submit" className={"submit" + (this.state.valid ? " valid" : "")} value={this.state.isComment ? "Comment" : "Post"} />
      </form>
    );
  }
}

function between(num, min, max) {
  return min <= num && num <= max;
}