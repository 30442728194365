import React from 'react';

import PostForm from './PostForm';
import { NotifContainer } from './Notifs';

import callAPI from '../utilities/callAPI';
import { parsePost } from '../utilities/parsePost';

/**
 * Component for the comments section in a post.
 */
export default class Comments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // the comments to be shown, JSX
      comments: null
    }
  }

  onPost = (post) => {
    this.setState({ comments: [parsePost(post, { comment: true }), ...this.state.comments] });
    this.props.onComment();
  }

  async loadComments() {
    // call API
    const response = await callAPI("GET", "", "/comments/" + this.props.postId);
    var comments;
    if (response.success) {
      try {
        comments = JSON.parse(response.data);
      } catch (error) {
        NotifContainer.error("Failed to parse comments", error);
        return;
      }
      // parse and add comments to state to show
      let arr = [];
      for (let post of comments) {
        arr.push(parsePost(post, { comment: true }));
      }
      this.setState({ comments: arr });
    } else {
      NotifContainer.error("Failed to load comments", response.data);
    }
  }

  componentDidUpdate() {
    if (this.props.show && this.state.comments === null) {
      this.loadComments();
    }
  }

  render() {
    return (
      <div className={"comments " + (this.props.show ? "show" : "")}>
        <PostForm onPost={this.onPost} comment postId={this.props.postId} />
        <div className="comments-container">
          {this.state.comments}
        </div>
      </div>
    );
  }
}