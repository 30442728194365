import { NotifContainer } from "../components/Notifs";

/**
 * Calls the API.
 * @param {String} method method to call with (GET, POST, ...), optional
 * @param {String} body body of the call, optional
 * @param {String} url url to call, optional
 * @returns 
 */
export default async function callAPI(method = "GET", body = "", url = "/posts") {
  // create call object
  let obj = {
    method: method
  };
  if (method === "POST") {
    obj.body = body;
  }
  try {
    const response = await fetch("https://postr.kzhao14.workers.dev" + url, obj);
    let r = await response.text();
    // parse json here instead of doing response.json() so embedded json
    // doesn't get auto-parsed
    try {
      return JSON.parse(r);
    } catch (error) {
      NotifContainer.error("Failed to parse response", error);
    }
  } catch (error) {
    NotifContainer.error("Failed to Call", error);
  }
}