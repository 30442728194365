import React from 'react';

import NewPost from './NewPost';
import Sidebar from './Sidebar';
import { NotifContainer } from './Notifs';

import callAPI from '../utilities/callAPI';
import { parsePost } from '../utilities/parsePost';

/**
 * Parent Component for the home of the app - shows all posts by date.
 */
export default class Posts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // posts to be displayed, JSX
      posts: [],
    }
  }

  async loadPosts() {
    // call API
    const response = await callAPI("GET");
    var posts;
    if (response.success) {
      try {
        posts = JSON.parse(response.data);
      } catch (error) {
        NotifContainer.error("Failed to parse posts", error);
        return;
      }
      // parse posts and add to state to show
      let postsArr = [];
      for (let post of posts) {
        postsArr.push(parsePost(post));
      }
      this.setState({ posts: postsArr });
    } else {
      NotifContainer.error("Failed to retrieve posts", response.data);
    }
  }

  addPost = (post) => {
    let posts = this.state.posts;
    this.setState({ posts: [parsePost(post), ...posts] });
  }

  componentDidMount() {
    this.loadPosts();
  }

  render() {
    return (
      <div id="primary">
        <Sidebar />
        <div id="posts-container">
          <NewPost onPost={this.addPost} />
          {this.state.posts}
        </div>
      </div>
    );
  }
}