import React from 'react';

/**
 * Accessor to global NotifContainer, used to send notifs.
 */
export var NotifContainer;

/**
 * Component that holds all notifications.
 */
export default class Notifs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // notifs to display, JSX
      notifs: []
    }
  }

  componentDidMount() {
    NotifContainer = this;
  }

  removeNotif = (id) => {
    // find and remove notif
    for (let i = 0; i < this.state.notifs.length; i++) {
      if (this.state.notifs[i].key === "" + id) {
        let notifs = this.state.notifs;
        notifs.splice(i, 1);
        this.setState({ notifs: notifs });
        return;
      }
    }
  }

  /**
   * Creates a notification.
   * @param {String} type Type of notif (info, error)
   * @param {String} args Array of arguments. args[0]: message, args[1-]: logged data
   */
  notif(type, args) {
    // convert args to a single string
    let message = args[0];
    args = Array.prototype.slice.call(args, 1);

    // log info & data
    console.log(message, args.join(" "));

    // create notif
    let key = new Date().getTime();
    let notif = <Notif key={key} notifId={key} type={type} body={message} remove={this.removeNotif} />
    this.setState({
      notifs: [...this.state.notifs, notif]
    });
  }

  info(message) {
    this.notif("info", arguments);
  }

  error(message) {
    this.notif("error", arguments);
  }

  render() {
    return (
      <div id="notifs-container">
        {this.state.notifs}
      </div>
    );
  }
}

/**
 * Component for a single notification.
 */
class Notif extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // whether the notif is hidden
      hide: false
    }
  }

  componentDidMount() {
    setTimeout(() => { 
      // animate-out notif
      this.setState({ hide: true });
      setTimeout(() => {
        // remove notif
        this.props.remove(this.props.notifId);
      }, this.props.animTime || 400);
    }, (this.props.timeout || 2000) + (this.props.animTime || 400));
  }

  render() {
    return (
      <div className={"notif " + this.props.type + (this.state.hide ? " hide" : "")} >
        <p className="notif-body">{this.props.body}</p>
      </div>
    );
  }
}