// adjectives
const adj = ['jittery', 'evanescent', 'tacky', 'elegant', 'acrid', 'classy', 'fearful', 'brawny', 'skinny', 'valuable', 'steady', 'wonderful', 'odd', 'serious', 'silky', 'grouchy', 'groovy', 'magnificent', 'towering', 'imminent', 'irritating', 'flagrant', 'productive', 'intelligent', 'fast', 'likable', 'divergent', 'sincere', 'civil', 'crabby', 'invincible', 'violent', 'exultant', 'neighborly', 'pushy', 'excited', 'ubiquitous', 'mysterious', 'romantic', 'ordinary', 'complex', 'sleepy', 'rare', 'polite', 'receptive', 'incompetent', 'greedy', 'scary', 'exclusive', 'colorful', 'quaint'];
// nouns
const nouns = ['salad', 'orange', 'duck', 'cake', 'cow', 'building', 'grape', 'crayon', 'volcano', 'horse', 'car', 'basket', 'coat', 'bottle', 'frog', 'basketball', 'fork', 'crib', 'toothbrush', 'dog', 'bell', 'door', 'bubble', 'book', 'pencil', 'farm', 'friend', 'bear', 'glove', 'camera', 'king', 'village', 'piano', 'movie', 'football', 'flower', 'cookie', 'photo', 'coffee', 'river', 'mountain', 'bird', 'pie', 'diamond', 'oven', 'lake', 'cat', 'tale', 'tea', 'poem'];
// characters
const chars = ['-', '_', '.'];

// rates for modifications
const capitalizeRate = 0.05;
const firstLetterModifier = 10;
const charsRate = 0.3;

/**
 * Generates a username based on the words and pattern provided.
 * @param {} pattern Pattern to generate with. "a": adjective, "c": character, "n": noun, "?": optional
 * @returns 
 */
export default function generateUsername(pattern = "ac?ac?nc?") {
  let username = "";
  // decipher pattern
  for (let i = 0; i < pattern.length; i++) {
    // word for this part of the pattern
    let str = "";
    if (pattern[i] === 'a') {
      // check for optional mark
      if (i < pattern.length - 1 && pattern[i + 1] === '?') {
        i++;
        if (rand(0, 10) < 5) {
          continue;
        }
      }
      str = adj[rand(0, adj.length)];
    } else if (pattern[i] === 'n') {
      if (i < pattern.length - 1 && pattern[i + 1] === '?') {
        i++;
        if (rand(0, 10) < 5) {
          continue;
        }
      }
      str = nouns[rand(0, nouns.length)];
    } else if (pattern[i] === 'c') {
      if (i < pattern.length - 1 && pattern[i + 1] === '?') {
        i++;
        if (Math.random() > charsRate) {
          continue;
        }
      }
      str = chars[rand(0, chars.length)];
    }
    // randomly capitalize
    for (let j = 0; j < str.length; j++) {
      if (Math.random() < capitalizeRate * (j === 0 ? firstLetterModifier : 1)) {
        str = str.substring(0, j) + str[j].toUpperCase() + str.substring(j + 1);
      }
    }
    username += str;
  }
  return username;
}

function rand(min, max) {
  return Math.floor(Math.random() * (max - min) + min);
} 