import React from 'react';

import Sidebar from './Sidebar';

import callAPI from '../utilities/callAPI';
import { parsePost } from '../utilities/parsePost';

/**
 * Component for viewing a single post.
 */
export default class SinglePost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // ID of the post
      postId: props.postId || "",
      // the post
      post: null
    }
  }

  async loadPost() {
    // call API
    const response = await callAPI("GET", "", "/post/" + this.state.postId);
    if (response.success) {
      // parse post and update
      let post = response.data;
      this.setState({
        post: parsePost(
          post, 
          {
            showComments: true, 
            showParent: post.parent !== undefined
          }
        )
      });
    } else {
      // if post doesn't exist, tell user
      let e = (
        <div className="post">
          <h2 className="error">
            Sorry, this post doesn't seem to exist.<br /><br />
            <a href="/" className="home button">Home</a>
          </h2>
        </div>
      );
      this.setState({ post: e });
    }
  }

  componentDidMount() {
    this.loadPost();
  }

  render() {
    return (
      <div id="primary">
        <Sidebar />
        <div id="posts-container">
          {this.state.post}
        </div>
      </div>
    );
  }
}