/**
 * Parses a date into a relative string.
 * @param {Number} d milliseconds since Jan 1, 1970 
 * @returns String of date relative to now
 */
export function parseDate(d) {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  let diff = Math.round((new Date() - d) / 1000);
  // Now
  if (diff === 0) {
    return "Now";

    // Seconds
  } else if (diff < 60) {
    return diff + (diff === 1 ? " second ago" : " seconds ago");

    // Minutes
  } else if (diff < 3600) {
    let num = Math.round(diff / 60);
    return num + (num === 1 ? " minute ago" : " minutes ago");

    // Hours
  } else if (diff < 86400) {
    let num = Math.round(diff / 60 / 60);
    return num + (num === 1 ? " hour ago" : " hours ago");

    // Date
  } else {
    let date = new Date(d);
    let curr = new Date();
    if (date.getFullYear() === curr.getFullYear()) {
      return months[date.getMonth()] + " " + date.getDate();
    } else {
      return months[date.getMonth()] + " " + date.getDate() + ", " + date.getFullYear();
    }
  }
}

/**
 * Parses a number into a number string.
 * @param {Number} n number to parse 
 * @returns String of the number
 */
export function parseNum(n) {
  if (n === 0) {
    return "";
  } else if (n < 1000) {
    return n + "";
  } else if (n < 1000000) {
    return Math.round(n / 100) / 10 + "K";
  } else {
    return Math.round(n / 100000) / 10 + "M";
  }
}

/**
 * Parses content into HTML
 * @param {*} c content to parse
 * @returns Array of strings/JSX
 */
export function parseContent(c) {
  // transform into array if necessary
  if (typeof c === "string") {
    return parseContent([c]);
  }

  let res = [];
  // parse each element of array
  for (let e of c) {
    // if element is a string, search for keywords
    if (typeof e === "string") {
      for (let i = 0; i < e.length; i++) {
        let s = e.substring(i);
        let index;
        
        // turn newlines into <br />
        if ((index = s.search(/\n/)) > -1) {
          // parse the inside text before putting it with br
          let text = parseContent([s.substring(0, index)]);
          res.push(<span>{text}<br /></span>);
          i += index;

          // turn <link /> into <a />
        } else if (s.search(/^<link>(.+)<\/link>/) > -1) {
          let link = s.substring(6).replace(/<\/link>.*/, "");
          res.push(<a href={link} rel="noreferrer" target="_blank">{link}</a>);
          i += link.length + 12;

          // turn <image /> into <img />
        } else if (s.search(/^<image>(.+)<\/image>/) > -1) {
          let link = s.substring(7).replace(/<\/image>.*/, "");
          res.push(<span className="image"><img src={link} alt="" /></span>);
          i += link.length + 14;

          // bold
        } else if (s.search(/^<b>(.+?)<\/b>/) > -1) {
          let text = s.substring(3).replace(/<\/b>.*/, "");
          let content = parseContent(text);
          res.push(<b>{content}</b>);
          i += text.length + 6;

          // italics
        } else if (s.search(/^<i>(.+?)<\/i>/) > -1) {
          let text = s.substring(3).replace(/<\/i>.*/, "");
          let content = parseContent(text);
          res.push(<i>{content}</i>);
          i += text.length + 6;

          // underline
        } else if (s.search(/^<u>(.+?)<\/u>/) > -1) {
          let text = s.substring(3).replace(/<\/u>.*/, "");
          let content = parseContent(text);
          res.push(<u>{content}</u>);
          i += text.length + 6;

          // code
        } else if (s.search(/^<code>(.+?)<\/code>/) > -1) {
          let text = s.substring(6).replace(/<\/code>.*/, "");
          res.push(<code>{text}</code>);
          i += text.length + 12;

          // turn &lt; into <
        } else if (s.search(/^&lt;/) > -1) {
          res.push("<");
          i += 3;

          // turn &gt; into >
        } else if (s.search(/^&gt;/) > -1) {
          res.push(">");
          i += 3;

          // otherwise, just add as a normal string
        } else {
          if (typeof res.at(-1) !== "string") {
            res.push(e[i]);
          } else {
            res[res.length - 1] = res.at(-1) + e[i];
          }
        }
      }
      
      // if is React object, parse its children
    } else if (typeof e === "object") {
      let r = parseContent(e.props.children);
      res.push(...r);
    }
  }

  return res;
}