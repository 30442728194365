import React from 'react';
import { BsHeart, BsHeartFill, BsChatLeft, BsLink45Deg } from 'react-icons/bs';

import { NotifContainer } from './Notifs';

import callAPI from '../utilities/callAPI';
import { parseNum } from '../utilities/parse';
import { username } from '..';

/**
 * Component for the interaction on a post - likes, comments, and shares.
 */
export default class PostInteract extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // ID of the post
      postId: props.postId || "",
      // number of likes
      likes: props.likes || 0,
      // whether the user has liked the post
      liked: props.liked || false
    }
  }

  handleLike = async () => {
    // create data object
    let obj = {
      username: username,
      post: this.state.postId
    };

    // call API
    let res = await callAPI("POST", JSON.stringify(obj), "/like");
    if (res.success) {
      // update likes
      this.setState({
        likes: this.state.likes + (this.state.liked ? -1 : 1),
        liked: !this.state.liked
      });
    } else {
      NotifContainer.error("Failed to like post", res.data);
    }
  }

  share = () => {
    // copy link to clipboard
    navigator.clipboard.writeText(window.location.origin + "/post/" + this.state.postId);
    NotifContainer.info("Link Copied");
  }

  render() {
    return (
      <div className="interact" >
        <div className={"likes" + (this.state.liked ? " liked" : "")} onClick={this.handleLike} title="Like">
          {this.state.liked
            ? <BsHeartFill className="heart" />
            : <BsHeart className="heart" />
          }
          <span className="int-count">{parseNum(this.state.likes)}</span>
        </div>
        <div className="comments" onClick={this.props.showComments} title="Comment">
          <BsChatLeft className="comment" />
          <span className="int-count">{parseNum(this.props.comments)}</span>
        </div>
        <div className="share" onClick={this.share} title="Share">
          <BsLink45Deg className="link" />
        </div>
      </div>
    );
  }
}