import React from 'react';
import ReactDOM from 'react-dom';
import { useCookies, CookiesProvider } from "react-cookie";

import Home from './components/Home.jsx';
import SinglePost from './components/SinglePost.jsx';
import Notifs from './components/Notifs.jsx';

import generateUsername from "./utilities/generateUsername.js";

import './index.css';

export var username;

export function setUsername(u) {
  username = u;
}

const path = window.location.pathname;

function App() {
  const [cookies, setCookie] = useCookies(["username"]);

  username = cookies.username;
  if (username === undefined) {
    username = generateUsername();
    setCookie("username", username);
  }

  if (path.startsWith("/post")) {
    let p = path.split("/");
    if (p.length === 3) {
      return (
        <div>
          <SinglePost postId={p[2]} />
          <Notifs />
        </div>
      );
    }
  }

  return (
    <div>
      <Home />
      <Notifs />
    </div>
  );
}

ReactDOM.render(
  <CookiesProvider>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </CookiesProvider>,
  document.getElementById('root')
);
