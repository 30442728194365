import React from 'react';

import PostForm from './PostForm';

import { username, setUsername } from '..';

/**
 * Component for writing a new post, used at the top of the Home.
 */
export default class NewPost extends React.Component {
  handleSave(u) {
    setUsername(u);
    document.cookie = "username=" + u;
  }

  render() {
    return (
      <div className="post newpost">
        <EditableText className="user" prefix="&gt; " onSave={this.handleSave}>
          {username}
        </EditableText>
        <PostForm onPost={this.props.onPost} />
      </div>
    );
  }
}

/**
 * A textbox that becomes editable once clicked.
 */
class EditableText extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // prefix, if any, to display but not edit
      prefix: props.prefix || "",
      // whether is currently editing
      edit: false,
      // text that is editable
      text: props.children || "",
    };
    this.textInput = React.createRef();
    this.handleSave = props.onSave;
  }

  toggle = (event) => {
    event.preventDefault();
    if (this.state.edit) {
      this.handleSave(this.state.text);
    }
    this.setState({ edit: !this.state.edit });
  }

  handleChange = (event) => {
    const value = event.target.value;
    this.setState({ text: value });
  }

  componentDidUpdate() {
    if (this.state.edit) {
      this.textInput.current.focus();
    }
  }

  render() {
    return (
      <div className={"editable-text" + (this.state.edit ? " edit" : "")}>
        <p className={"text " + this.props.className} onClick={this.toggle}>{this.state.prefix + this.state.text}</p>
        <form onSubmit={this.toggle}>
          <input
            ref={this.textInput}
            className={"input " + this.props.className}
            type="text" value={this.state.text}
            onChange={this.handleChange}
          />
        </form>
      </div>
    );
  }
}