import Post from '../components/Post';

import { parseDate } from './parse';

import { username } from '..';

/**
 * Parses a post object into a Post.
 * @param {Object} post post object data
 * @param {Object} options options
 * @returns a Post react object
 */
export function parsePost(post, options = {}) {
  return (
    <Post
      key={"k" + post.id}
      postId={post.id}
      username={post.username}
      title={post.title}
      content={post.content}
      date={parseDate(post.date)}
      likes={post.likes.length}
      liked={post.likes.includes(username)}
      comments={post.comments}
      
      comment={options.comment}
      showComments={options.showComments}
      showParent={options.showParent}
      parent={options.showParent && post.parent}
      parentUsername={options.showParent && post.parentUsername}
    />
  );
}