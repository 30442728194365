import React from 'react';
import { BsHouse } from 'react-icons/bs';

/**
 * Component for the sidebar.
 */
export default class Sidebar extends React.Component {
  render() {
    return (
      <div id="sidebar">
        <a className="home button" href="/"><BsHouse /><span>Home</span></a>
      </div>
    );
  }
}