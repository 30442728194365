import React from 'react';

import PostInteract from './PostInteract';
import Comments from './Comments';

import { parseContent } from '../utilities/parse';

const maxContentHeight = 1000;

/**
 * Component for a single post.
 */
export default class Post extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // title of the post, parsed
      title: parseContent(props.title || ""),
      // content/body of the post, parsed
      content: parseContent(props.content || ""),
      // whether the post exceeds the height limit
      shorten: false,
      // whether to expand the post
      expand: false,
      // whether the comments are visible/shown
      showComments: props.showComments || false,
      // whether the post is a comment
      isComment: this.props.comment || false,
      // the number of comments, used to update PostInteract
      comments: props.comments || 0
    }
    this.content = React.createRef();
  }

  componentDidMount = () => {
    if (!this.props.expand && this.content.current.clientHeight > maxContentHeight) {
      this.setState({ shorten: true });
    }
  }

  expand = () => {
    if (this.state.shorten) {
      this.setState({ shorten: false, expand: true });
    }
  }

  showComments = () => {
    this.setState({ showComments: !this.state.showComments });
  }

  onComment = () => {
    this.setState({ comments: this.state.comments + 1 });
  }

  render() {
    return (
      <div className="post">
        <p className="user">&gt; {this.props.username}</p>
        <p className="date">{this.props.date}</p>
        {this.props.showParent && 
          <p className="parent">Replying to <a href={"/post/" + this.props.parent}>&gt; {this.props.parentUsername}</a></p>
        }
        <h1 className="title">{this.state.title}</h1>
        <div className={"content" + (this.state.shorten ? " shorten" : "") + (this.state.expand ? " expand" : "")} ref={this.content}>
          {this.state.content}
        </div>
        <span className="expand" onClick={this.expand}>View More</span>
        <PostInteract
          likes={this.props.likes}
          liked={this.props.liked}
          postId={this.props.postId}
          showComments={this.showComments}
          comments={this.state.comments}
        />
        <Comments show={this.state.showComments} postId={this.props.postId} onComment={this.onComment} />
      </div>
    );
  }
}